import { Button, Flex, Image, Skeleton, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiCheckSquare } from "react-icons/fi";
import logoImage from '../images/Symliphy_Logos_WEB_1024 x 500/Symliphy_Logo_2Color on Dark.png';

export const Hero = ({ onButtonClick }) => {
    const [pharmacyKeyExists, setPharmacyKeyExists] = useState(localStorage.getItem('pharmacy') !== null && localStorage.getItem('pharmacy') !== '');
    const [bgOption, setBgOption] = useState("altbackground.jpg");
    const [isContentVisible, setIsContentVisible] = useState(false); // New state to control visibility

    useEffect(() => {
      const checkPharmacyKey = () => {
          const exists = 
            (localStorage.getItem('pharmacy') !== null && localStorage.getItem('pharmacy') !== '')
            ||
            (localStorage.getItem('source') && localStorage.getItem('source').includes('gianteagle'))
            ;
          //console.log('exists',exists)
          setPharmacyKeyExists(exists);
      };
      checkPharmacyKey();
      setIsContentVisible(true); // Enable rendering after the delay

      const bgOptionKey = localStorage.getItem('option');

      if (bgOptionKey === "1") {
        setBgOption("bg1.jpg")
      } else if (bgOptionKey === "2") {
        setBgOption("altbackground.jpg")
      } else if (bgOptionKey === "3") {
        setBgOption("bg3.jpg")
      } else if (bgOptionKey === "4") {
        setBgOption("bg4.jpg")
      } else {
        setBgOption("bg6.jpg")
        //console.log('bgoption6')
      }
    }, []);

    // Conditional rendering based on `isContentVisible`
    if (!isContentVisible) {
        return <Skeleton w={'100%'} h={'640px'} />; // Placeholder or loading indicator
    }

    return (
    <Flex
      as="section"
      background={pharmacyKeyExists ? "linear-gradient(135deg, rgba(10, 10, 19, 0.8) 25%, rgba(252, 98, 40, 0.4) 100%), url(../photos/shopping.jpg)"
      : `linear-gradient(135deg, rgba(10, 10, 19, 0.7) 0%, rgba(130, 98, 40, 0.4) 60%), url(../photos/${bgOption})`
      }
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={pharmacyKeyExists ? 'center 30%' : { base:"80% 30%",md:"center 30%"}}
      py={{ base: 16, md: 20 }}
      px={{ base: 4, md: 8 }}
      justifyContent={'center'}
    >
      <Flex maxW={'1280px'} w={'100%'}>
        <Stack spacing={{ base: '8', md: '10' }}>
          <Stack spacing={{ base: '4', md:'6' }} color="white">
            
              <Stack spacing="3">
              {pharmacyKeyExists ?
              <>
                <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight="500" color="accent">
                  Easier than your shopping list
                </Text>
                <Flex 
                  flexDirection={'column'} textAlign={'left'}
                  fontSize={{ base: '2xl', md: '4xl' }} fontWeight="semibold"
                  width={{base:'200px', md:'250px'}}  
                >
                  <Flex alignItems={'center'} gap={5}><FiCheckSquare /><Text>Tissues</Text></Flex>
                  <Flex alignItems={'center'} gap={5}><FiCheckSquare /><Text>Soup</Text></Flex>
                  <Flex alignItems={'center'} gap={5}><FiCheckSquare /><Text>Treatment</Text></Flex>
                </Flex>
                
              
                <Image src={logoImage} w={{base:"300px"}} mt={3} />
              </>
              :
              <Text color="fg.muted" fontWeight='bold' fontSize={{ base: '2.4em', md: '3.2em' }} maxW="2xl">
                From Symptom to Care, FASTER
              </Text>
              }  
              </Stack>
            
            <Text color="fg.muted" fontSize={{ base: 'lg', md: '2xl' }} maxW="2xl">
              Access fast symptom relief that's convenient, affordable, discreet and secure, so you're feeling better, faster.
            </Text>
            <Text color="fg.muted" fontSize={{ base: 'xl', md: '3xl' }} maxW="2xl" fontWeight={'bold'}>
              Starting at $29
            </Text>
          </Stack>
          <Stack direction={{ base: 'column-reverse', md: 'row' }}>
            <Button size="xl" py={4} px={6} variant={'primaryYellowSolid'} boxShadow={'symShadow'}
              onClick={onButtonClick}
            >
              Find Treatment
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}