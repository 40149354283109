import {
  Button, Flex,
  Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const CTABlock = ({ tab }) => {
  const navigate = useNavigate();
  //console.log('tab', tab)

  const handleFindTreatmentClick = () => {
    if (tab === 2) {
      navigate('/treatments/weight-loss');
    } else {
      navigate('/care');
    }
  };

  const bodyText = tab === 2 ? 'Symliphy propels you through the weight loss treatment process, fast as a flash, discussing your health concerns and providing guidance through simple checklists, and virtual visits.' : 'Symliphy propels you through time to each step, fast as a flash, discussing your health concerns and providing guidance through simple checklists, and virtual visits.';
  const buttonText = tab === 2 ? 'Start Weight Loss Journey' : 'Find a Treatment';

  return (
    <>
      <Flex
        as="section"
        background={'secondaryBlue.default'}
        py={{ base: 8, md: 12 }}
        px={{ base: 8, md: 24 }}
        width="100%"
        justifyContent={'space-between'}
        flexDirection={'column'}
        textAlign={'center'}
        alignItems={'center'}
        gap={5}
      >
        <Text fontSize={'30px'} color={'white'} fontWeight={'bold'}>Get Started Now</Text>
        <Text fontSize={{base:'18px',md:'20px'}} color={'white'} maxWidth={'800px'} margin="0 auto">
          {bodyText}
        </Text>
        <Button variant={'primaryYellowSolid'} size="xl" py={4} px={6} mt={4}
          onClick={handleFindTreatmentClick}
        >
          {buttonText}
        </Button>
      </Flex>
    </>
  )
}
