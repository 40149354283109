import { Box, Flex, Image, Text } from '@chakra-ui/react';
import * as React from "react";
import Chatbot from "react-chatbot-kit";

import { TfiClose } from "react-icons/tfi";
import ActionProvider from "../chatbot/ActionProvider.jsx";
import MessageParser from "../chatbot/MessageProvider.jsx";
import config from "../chatbot/chatbotConfig.js";
import { useChatbot } from '../contexts/ChatbotContext.jsx';
import iconImage from '../images/faviconwhite.png';

export const ChatbotComponent = () => {

  const { showBot, toggleBot } = useChatbot();
  //console.log('showBot:', showBot);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      const newInnerHeight = window.innerHeight;
      setWindowHeight(newInnerHeight); // Update state

      const newHeight = Math.max(newInnerHeight - 250, 327) + 'px'; // Calculate new height for the chat
      document.documentElement.style.setProperty('--dynamic-chat-height', newHeight); // Update CSS variable
    };

    // Perform initial update
    handleResize();

    // Set up event listener for future resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  return (
    <>
      {showBot ?
      <>
        <Box className="chatbot-parent-container" position="fixed" 
          bottom={{base:'110px',md:'110px'}} right={{base:0, md:'20px'}} 
          bgColor="white" border="solid 1px" borderColor={'black'} borderRadius={'16px'}
          width={{base:'100%', md:'350px'}} height={{base:`${windowHeight - 110}px`, md:'450px', lg:'550px'}}
        >
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </Box>
        <Flex borderRadius='50%' position="fixed" 
          bottom={{base:'20px', md:'20px'}} right={{base:'unset', md:'20px'}} left={{base:'20px', md:'unset'}} 
          bgColor={'primaryOrange.default'} boxShadow={'symShadowDarker'}
          height="75px" width="75px" cursor={'pointer'} zIndex={502} justifyContent={'center'} alignItems={'center'} 
          onClick={() => toggleBot(!showBot)}
        >
          <TfiClose size={'23px'} color='white' />
        </Flex>
        <Text position="fixed" bottom={{base:'42px', md:'42px'}} right={{base:'unset', md:'90px'}} left={{base:'90px', md:'unset'}} zIndex={501}
          bgColor={'white'} color={'primaryOrange.default'} border='solid 1px' width='125px' height='30px' textAlign={'center'} 
          borderRightRadius={{base:'8px', md:0}} borderLeftRadius={{base:'0px', md:'8px'}}
          fontWeight={'bold'} boxShadow={'symShadowDarker'} cursor={'pointer'} onClick={() => toggleBot(!showBot)}
        >
          Close
        </Text>
      </>
      : 
      <>
        <Box borderRadius='50%' position="fixed" 
          bottom={{base:'60px', md:'20px'}} right={{base:'unset', md:'20px'}} left={{base:'20px', md:'unset'}} 
          bgColor={'primaryOrange.default'} boxShadow={'symShadowDarker'}
          height="75px" width="75px" cursor={'pointer'} zIndex={502}
          onClick={() => toggleBot(!showBot)}
          display={{base:'none', md:'block'}}
        >
          <Image src={iconImage} padding={'18px 21px 16px 18px'} />
        </Box>
        <Text position="fixed" bottom={{base:'81px', md:'42px'}} right={{base:'unset', md:'90px'}} left={{base:'90px', md:'unset'}} zIndex={501}
          bgColor={'white'} color={'primaryOrange.default'} border='solid 1px' width='125px' height='30px' textAlign={'center'} 
          borderRightRadius={{base:'8px', md:0}} borderLeftRadius={{base:'0px', md:'8px'}}
          fontWeight={'bold'} boxShadow={'symShadowDarker'} cursor={'pointer'} onClick={() => toggleBot(!showBot)}
          display={{base:'none', md:'block'}}
        >
          Receptionist
        </Text>
      </>
      }
    </>
  );
}